import React, { useEffect } from "react";
import CategoriaBar from "./CategoriaBar";
import MidPageEmpresarial from "./MidPageEmpresarial"
import TopBar from "./TopBar";

export default function EmpresarialSite() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <TopBar />
            <CategoriaBar />
            <MidPageEmpresarial />
        </div>
    )
}
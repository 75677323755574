import React from "react";
import DesayunosMenu from "./DesayunosMenu";
import CardList from "./CardList";
import { useSearchParams } from "react-router-dom";

export default function MidPage() {
    const [getQuery] = useSearchParams();
    let category = getQuery.get("category");

    function CheckType() {
        switch (category) {
            case "desayuno":
                return "Desayunos Y Meriendas";
            case "aperitivos":
                return "Aperitivos";
            case "infantil":
                return "Desayunos Infantiles";
            case "brindis":
                return "Brindis";
            case "bombones":
                return "Bombones-Peluches-Flores";
            case "flores":
                return "Flores";
            case "nacimiento":
                return "Set Nacimiento";
            case "delikatessen":
                return "Estuches con Delikatessen";
            case "vino":
                return "Estuches con Vinos y Copas";
            case "canasta":
                return "Canastas y Baules Premium";
            case "especiales":
                return "Regalos Especiales";
            case "opcionesparaagregar":
                return "Opciones para Agregar a tus Regalos"
            default:
                return "Regalos a Domicilio"
        }
    }

    return (
        <div className="w-full">
            <div className=" xl:w-[100%] 2xl:w-3/4 mx-auto flex">
                <DesayunosMenu />
                <div className="mx-auto">
                    <h3 className="text-[25px] text-violet-600 font-bold">{CheckType()}</h3>
                    <CardList />
                </div>

            </div>

        </div>
    )
}
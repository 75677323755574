import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import logo25B from "../Assets/logoOficialHori.png"
import carritoLogo from "../Assets/carrito.png"

export default function TopBar() {

    const [input, setInput] = useState();
    const [carritoLenth, setCarritoLenth] = useState();

    let GetPedido = localStorage.getItem("Pedido");
    const TranslatePedido = JSON.parse(GetPedido);

    useEffect(() => {
        if (TranslatePedido) {
            setCarritoLenth(TranslatePedido.length)
        }

    }, [TranslatePedido])

    function handleChange(e) {
        e.preventDefault();
        setInput(e.target.value);
    }

    return (
        <div className="bg-white w-full h-20  border-black border">

            <div className="
            xl:w-[100%] 2xl:w-3/4 h-[80px] 
            flex mx-auto justify-between">

                {/* Logo de la compania */}
                <div className="flex
                ml-[75px] sm:ml-[75px] md:ml-[100px] lg:ml-[100px] xl:ml-[100px] 2xl:ml-[0px]">
                    <div className="cursor-pointer mx-auto">
                        <Link to={"/"}>
                            <img src={logo25B} alt="Logo_img" className="
                            h-[40px] w-[133px] max-md:h-[50px] max-md:w-[166px] md:h-[60px] md:w-[200px] xl:h-[70px] xl:w-[233px] 2xl:h-[75px] 2xl:w-[250px] 
                            mt-3 sm:mt-3 md:mt-3 xl:mt-2 2xl:mt-1 max-md:ml-[0px]" />
                        </Link>

                    </div>
                </div>

                {/* input-buscador de productos 
                <div className="basis-1/3 flex">
                    <form className=" mt-5 mx-auto hidden" onSubmit={(e) => { handleSubmit(e) }}>
                        <button type="submit" className="rounded-l-full bg-gray-400 hover:bg-gray-600 h-8 w-10 grid content-center">
                            <img className=" h-6 w-6 justify-self-center" src={iconoLupa} alt="Buscar" />
                        </button>
                        <input type="text" value={input} onChange={(e) => { handleChange(e) }} placeholder='Busca un Producto...' className=" flex-auto rounded-r-full p-0.5 bg-gray-200 border-0 caret-red-400 w-[275px] h-8" />
                    </form>
                </div>*/}

                {/* carrito */}
                <div className="basis-1/3 py-5 px-5">
                    <div className=" flex rounded-[10px] bg-sky-500 hover:bg-sky-700 w-[130px] h-[35px] cursor-pointer mx-auto">
                        <img src={carritoLogo} alt="Carrito_Logo" className="
                        w-[18px] h-[18px] sm:w-[20px] sm:h-[20px] md:w-[25px] md:h-[25px] 
                        mx-auto my-auto" />
                        <Link to={"/Carrito"}>
                            <button className="w-20 h-8">
                                {carritoLenth == null || carritoLenth == "" 
                                ? <p className="font-semibold tracking-wide text-center text-white text-[14px] md:text-[16px] lg-[18px]">Carrito</p> 
                                : <p className="font-semibold tracking-wide text-center text-white text-[14px] md:text-[16px] lg-[18px]">Carrito{"["}{carritoLenth}{"]"}</p>}
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}
import React from "react";
import { Link } from "react-router-dom";

export default function CategoriaBar() {
    return (
        <div className="flex max-sm:flex-col flex-row 
        justify-between w-[100%] 2xl:w-[75%] mx-auto mt-2">
            {/*Desayunos*/}
            <Link to={"/Domicilio?type=domicilio"} className="basis-[45%]">
                <div className="bg-violet-600 hover:bg-violet-800 rounded-xl  h-[50px] rounded-l-lg cursor-pointer flex max-sm:mb-2">
                    <p className=" text-white uppercase 
                    sm:text-[18px] md:text-2xl 
                    font-semibold m-auto">Regalos a domicilio</p>
                </div>
                
            </Link>
            {/*Regalos Empresariales*/}
            <Link to={"/Empresarial?type=empresarial"} className="basis-[45%] ">
                <div className="bg-purple-600 hover:bg-purple-800 h-[50px] rounded-xl cursor-pointer flex">
                    <p className="text-white uppercase 
                    sm:text-[18px] md:text-2xl 
                    font-semibold m-auto">Regalos Empresariales</p>
                </div>
                
            </Link>

        </div>
    )
}
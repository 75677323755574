import Footer from './Components/Footer';
import MainPage from './Components/MainPage';
import { Route,  Routes } from 'react-router-dom';
import DomicilioSite from './Components/DomicilioPath';
import EmpresarialSite from './Components/EmpresarialPath';
import DetalleProducto from "./Components/DetalleProducto";
import CarritoSite from "./Components/CarritoPath"

import ProdListAdmin from "./Components/Admin/TopBar"
import AdminCategoryBar from "./Components/Admin/AdminProductos"
import CreateProd from './Components/Admin/CreateProducto';
import EditProduct from "./Components/Admin/EditProduct";
import Registro from "./Components/Admin/Registro/Registro"
import PedidoData from "./Components/Admin/Registro/PedidoCard"
import Nosotros from './Components/Nosotros';
import Question from './Components/Question';

import RequireAuth from './Components/Admin/RequiredAuth';
import Missing from './Components/Missing';


function App() {
  return (
    <div className=" bg-[#e2e8f0] h-screen relative min-h-[100vh]">
      <Routes className="border-2 border-red-600 bg-amber-500">
        <Route exact path='/' element={<MainPage />} />
        <Route path="/Domicilio" element={<DomicilioSite />} />
        <Route path="/productos.php?producto=1" element={<DomicilioSite />} />
        <Route path="/busqueda.php?category=1&producto=1" element={<DomicilioSite />} />
        <Route path="/busqueda.php?category=11&producto=1" element={<DomicilioSite />} />
        <Route path="/busqueda.php?category=5&producto=1" element={<DomicilioSite />} />
        <Route path="/busqueda.php?category=61&producto=2" element={<DomicilioSite />} />
        <Route path="/Empresarial" element={<EmpresarialSite />} />
        <Route path='/Domicilio/Producto' element={<DetalleProducto />} />
        <Route path='/Empresarial/Producto' element={<DetalleProducto />} />
        <Route path="/Carrito" element={<CarritoSite />} />
        <Route path="/Nosotros" element={<Nosotros />} />
        <Route path="/Preguntas" element={<Question />} />

        <Route path='/Admin' element={<ProdListAdmin />} />
        <Route element={<RequireAuth />}>
          <Route path='/Admin/log' element={<AdminCategoryBar />} />
          <Route path='/Admin/log/Create' element={<CreateProd />} />
          <Route path='/Admin/log/Edit' element={<EditProduct />} />
          <Route path="/Admin/Registro" element={<Registro />} />
          <Route path="/Admin/Registro/Pedido" element={<PedidoData />} />
        </Route>
        <Route path="*" element={<Missing />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";
import TopBar from "./TopBar";

export default function Missing() {
    return (
        <>
            <TopBar />
            <div className=" flex flex-col mt-10">
                <h1 className="mx-auto font-medium text-[20px]">Ooops!</h1>
                <p className="mx-auto font-medium text-[20px]">Parece que esta pagina no existe!</p>
                <div className="flex">
                    <Link to="/" className="mx-auto mt-10">
                        <div className=" my-auto font-medium text-[20px] text-white p-3 bg-violet-600 hover:bg-violet-800 rounded-xl w-[240px] h-[60px] text-center">
                            Volver a Pagina Principal
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}
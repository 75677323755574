import CarritoProd from "./CarritoProd";

export default function Carrito() {
    return (
        <>
            <div className="">
                <CarritoProd/>
            </div>
        </>
    )
}
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AniversarioImg from "../Assets/25aniversario.jpeg"
import AniversarioImg2 from "../Assets/ImperialSlick2.jpeg"

export default function SlickComponent() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScrool: 1,
        lazyLoad: true,
        autoplay: true,
        pauseOnHover: true
    };

    /** 
     * <div className="
                        mx-auto my-4
                        hidden sm:hidden md:block">
            


        </div>
     * <div className="bg-white flex rounded-xl shadow-2xl ">
                    <img src={AniversarioImg} alt="Slick-one" className="mx-auto rounded-xl" />
                </div>*/



    return (
        <Slider {...settings} className=" hidden sm:hidden md:block mb-5 mt-5 w-[90%] mx-auto">
            <div className="flex">
                <img src={AniversarioImg} alt="Slick-one" className="mx-auto w-[1000px]"  />
            </div>
            <div className="flex">
                <img src={AniversarioImg2} alt="Slick-one" className="mx-auto w-[1000px]" />
            </div>

        </Slider>
    )
}
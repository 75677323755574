import React, { useState, useEffect } from "react";
import DesayunosMenu from "./DesayunosMenu";
import EmpresarialMenu from "./EmpresarialMenu";
import CategoriaBar from "./CategoriaBar";
import TopBar from "./TopBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetOneProductByIDQuery } from "../AppStore/ApiSlice";
import ReactWhatsapp from "react-whatsapp";

export default function Detalle() {
    const [getQuery] = useSearchParams();
    let id = getQuery.get("id");
    const { data: Producto, isError, isLoading, error } = useGetOneProductByIDQuery(id);

    const [currentZoomed, setCurrentZoomed] = useState();
    const [imageVisible, setImageVisible] = useState("absolute cursor-pointer w-full z-[55] hidden");

    const [currentName, setCurrentName] = useState();
    const [currentDetalle, setCurrentDetalle] = useState();
    const [currentPrice, setCurrentPrice] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [currentCuantity, setCurrentCuantity] = useState(1);
    const [currentType, setCurrentType] = useState();
    const [activeButton, setActiveButton] = useState();

    const nav = useNavigate();


    useEffect(() => {
        if (!isLoading) {
            setCurrentName(Producto.Nombre)
            setCurrentDetalle(Producto.Detalle);
            setCurrentPrice(Producto.Precio);
            setTotalPrice(Producto.Precio);

            setActiveButton(Producto.Nombre);

            if (Producto.Tipo && Producto.Tipo == "domicilio" || Producto.Categoria && Producto.Categoria.includes("desayuno&merienda", "aperitivos", "infantil", "brindis", "bombones", "flores", "nacimiento", "delikatessen", "vino&copas", "canasta&baules", "especiales", "opcionesparaagregar")) {
                setCurrentType("Domicilio")
            } else {
                setCurrentType("Empresarial")
            }
        }
    }, [isLoading, Producto])

    if (isLoading) return (<div>is loading</div>);
    else if (isError) return <div>Error: {error.message}</div>;

    function SetZoomedImage(image) {
        setCurrentZoomed(image)
        setImageVisible("absolute cursor-pointer w-full z-[55] visible")
    }
    function disableZommedImage() {
        setImageVisible("absolute cursor-pointer w-full z-[55] hidden")
    }

    function SetSelectedTier(tier_detalle, tier_Precio, tier_Nombre) {
        if (!Array.isArray(tier_detalle)) {
            setCurrentDetalle(tier_detalle.split(/\r?\n|\r|\n/g))
        } else (
            setCurrentDetalle(tier_detalle)
        )
        setCurrentName(tier_Nombre)
        setCurrentCuantity(1)
        setCurrentPrice(tier_Precio)
        setTotalPrice(tier_Precio)

        setActiveButton(tier_Nombre)

    }

    function SetMultiple(e) {
        e.preventDefault();

        setCurrentCuantity(e.target.value)
        setTotalPrice(currentPrice * e.target.value)

    }

    function OnHandleClick(e) {
        e.preventDefault();
        const Name = Producto.Nombre;
        const PrecioTotal = totalPrice;
        const PrecioUnidad = currentPrice;
        const Cantidad = currentCuantity;
        const Detalle = currentDetalle;
        const Tier = currentName;

        const Pedido = [];

        if (!localStorage.getItem("Pedido")) {
            Pedido.push({ Name, PrecioTotal, PrecioUnidad, Cantidad, Detalle, Tier })
            localStorage.setItem("Pedido", JSON.stringify(Pedido));
        } else {
            const GetPedido = localStorage.getItem("Pedido");
            const TranslatePedido = JSON.parse(GetPedido);
            TranslatePedido.push({ Name, PrecioTotal, PrecioUnidad, Cantidad, Detalle, Tier })
            localStorage.setItem("Pedido", JSON.stringify(TranslatePedido))
        }

        nav("/Carrito")
    }

    function CheckType() {
        if (type && type == "domicilio" || category && category.includes("desayuno&merienda", "aperitivos", "infantil", "brindis", "bombones", "flores", "nacimiento", "delikatessen", "vino&copas", "canasta&baules", "especiales", "opcionesparaagregar")) {
            if (type) {
                console.log(type);
            }
            if (category) {
                console.log(category)
            }
            setCurrentType("Domicilio")
        } else {
            if (type) {
                console.log(type);
            }
            if (category) {
                console.log(category)
            }
            setCurrentType("Empresarial")
        }
    }


    return (
        <>
            <TopBar />
            <CategoriaBar />



            <div className={imageVisible} onClick={() => disableZommedImage()}>
                <img src={currentZoomed} alt="ZoomedPhoto" className=" rounded-md w-[full] sm:w-[600px] sm:h-[400px] border-[1.5px] border-gray-500 shadow-lg mx-auto z-[70]" />
                <div className=" w-screen h-dvh bg-gray-700/50 z-[-30] absolute top-[-200px] sm:top-[-150px]" />
            </div>
            <div className="mx-auto z-[0]
            xl:w-[100%] 2xl:w-3/4 flex">
                {/* Revisar que el menu cambie dependiendo de la categoria del producto */}
                {currentType && currentType == "Domicilio" ?
                    <DesayunosMenu />
                    :
                    <EmpresarialMenu />
                }


                <div className="sm:w-[100%] md:w-2/3 bg-white m-2 rounded-lg drop-shadow-xl">

                    <div className="py-5 rounded-xl shadow-xl">
                        {/* Title box */}
                        <div className="lg:flex">
                            <div className="md:w-[75%] max-lg:mx-auto lg:w-[50%] ">
                                <h3 className="text-black text-center underline text-[30px] font-bold">{Producto.Nombre}</h3>
                            </div>
                            
                            {/**<div className="lg:w-[600px] mr-5 ml-5">
                                <p className="text-[18px] text-red-700 font-semibold italic text-justify">*Importante: Los dias, Jueves 24, Viernes 25, Sabado 26 y Domingo 27 de Octubre, no estaran disponibles nuestros servicios de envios a domicilio.</p>
                            </div>*/}
                            
                        </div>

                        {/* Precio-Comprar-Imagenes */}
                        <div className="flex max-lg:flex-col mt-3">

                            <div className="w-[300px] md:h-[125px] lg:h-[225px] flex max-lg:order-3 max-lg:mx-auto">

                                <div className="flex flex-col max-lg:mx-auto lg:m-auto">
                                    {/* Etiqueta Precio */}
                                    {Producto.Tipo == "domicilio" ?
                                        <div className="">
                                            {totalPrice != null ?
                                                <h3 className="text-center text-[20px] flex">Precio: <p className="font-bold ml-2">${totalPrice}</p></h3> : ""}

                                        </div> :
                                        <div>

                                        </div>
                                    }
                                    {/* Boton Cantidad */}
                                    <div className="flex justify-center">
                                        {Producto.Tipo == "domicilio" ?
                                            <div>
                                                <span className="text-[12px] pt-1">Cantidad:</span>
                                                <select className="text-[15px] rounded-md m-1 h-[20px] w-[40px] text-center" onChange={(e) => SetMultiple(e)} value={currentCuantity}>
                                                    <option value={"1"}>1</option>
                                                    <option value={"2"}>2</option>
                                                    <option value={"3"}>3</option>
                                                    <option value={"4"}>4</option>
                                                    <option value={"5"}>5</option>
                                                </select>
                                            </div> :
                                            <div></div>
                                        }
                                    </div>
                                    {/* Boton Comprar / Consultar */}
                                    {Producto.Tipo == "domicilio" ?
                                        <div className="">
                                            <div className="w-[150px] h-[50px] rounded-lg bg-cyan-600 hover:bg-cyan-800 text-center text-[20px] flex  uppercase cursor-pointer" onClick={(e) => OnHandleClick(e)}>
                                                <span className="m-auto text-white">
                                                    Comprar
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="">
                                            <div className="w-[150px] h-[50px] rounded-lg bg-cyan-600 hover:bg-cyan-800 text-center p-3 mx-auto text-white uppercase cursor-pointer">
                                                <ReactWhatsapp number="5493512075356" message="Hola! Solicito informacion de este producto${Producto.Nombre}.">
                                                    Consultar
                                                </ReactWhatsapp>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Image box */}
                            <div className="bg-gray-500 
                            md:w-[425px] md:h-[318px] lg:w-[300px] lg:h-[225px] max-lg:mx-auto
                            cursor-pointer max-lg:order-1">
                                <img src={Producto.Foto} alt="Imagen_Producto" onClick={() => SetZoomedImage(Producto?.Foto)} />
                            </div>

                            <div className=" max-lg:order-2 max-lg:flex max-lg:mx-auto">
                                {Producto.ImgExtra.map((prop, i) => (
                                    <div className="flex w-[50px] h-[50px] sm:w-[75px] sm:h-[75px] lg:w-[50px] lg:h-[50px] bg-slate-500 cursor-pointer" key={i}>
                                        <img src={prop} alt={`Extra_img_${i}`} onClick={() => SetZoomedImage(prop)} />
                                    </div>
                                ))}
                            </div>

                        </div>
                        {/* Descriptions */}
                        <div className=" max-lg:flex-col lg:flex">
                            <div className="flex flex-col md:w-[100%] lg:w-[70%]">
                                {/* Description box */}
                                {Producto.Descripcion || Producto.Descripcion != "" ?

                                    < div >
                                        <h3 className="w-[90%] mx-auto mt-2 text-violet-500 text-[20px] font-semibold">Descripcion:</h3>
                                        <div className="bg-gray-300 w-[90%] h-[auto] mx-auto rounded-lg text-left px-3 py-2">
                                            <p>{Producto.Descripcion}</p>
                                        </div>
                                    </div>
                                    : <></>
                                }

                                {/* Detalles box */}
                                <div>
                                    <h3 className="w-[90%] mx-auto mt-2 text-violet-500 text-[20px] font-semibold">Contenido:</h3>
                                    <div className="bg-gray-200 w-[90%] mx-auto px-2 py-1 rounded-lg">

                                        <ul className="list-disc list-inside">
                                            {currentDetalle == [] || currentDetalle == null ? "" :
                                                currentDetalle.map((prod, i) => (
                                                    <li key={i}>{prod}</li>
                                                ))}
                                        </ul>
                                    </div >
                                </div>

                                {/* Observaciones */}
                                {Producto.Observacion != "" ?
                                    <div className="w-[90% mx-auto]">
                                        <h3 className="w-[90%] mx-auto mt-2 text-violet-500 text-[20px] font-semibold">Observacion:</h3>
                                        <div className="bg-gray-300 w-[90%] mx-auto px-2 py-1 rounded-lg">
                                            <p>{Producto.Observacion}</p>
                                        </div>
                                    </div> : ""}

                            </div>
                            {/* Tiers Box */}
                            {Producto.Tipo == "domicilio" ?

                                <div className=" md:w-[100%] lg:w-[27%] flex flex-col">
                                    <h3 className="mt-2 text-violet-500 font-semibold">Alternativas para este Producto:</h3>
                                    <div className=" rounded-lg grid 
                                    gap-2 sm:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-1 lg:gap-y-5 
                                    content-start">

                                        <div className={activeButton != Producto.Nombre ? "w-[100%] bg-violet-200 h-[75px] sm:min-h-[100px] rounded-lg p-2 flex flex-col justify-between cursor-pointer shadow-xl font-semibold text-left" : "w-[100%] bg-violet-400 h-[75px] sm:min-h-[100px] rounded-lg p-2 flex flex-col justify-between cursor-pointer font-semibold text-left"} onClick={() => SetSelectedTier(Producto.Detalle, Producto.Precio, Producto.Nombre)}>
                                            <p className="tracking-normal leading-4 h-[60%] text-[14px] xl:text-[18px]">PRODUCTO BASE</p>
                                            <p className="h-[30%] text-center">$ {Producto.Precio}</p>
                                        </div>

                                        {Producto.Tier.map((prod, i) => (
                                            <div className={activeButton != prod.Descripcion ? "w-[100%] bg-violet-200 h-[75px] sm:min-h-[100px] rounded-lg p-2 flex flex-col justify-between cursor-pointer  shadow-xl font-semibold text-left" : "w-[100%] bg-violet-400 h-[75px] sm:min-h-[100px] rounded-lg p-2 flex flex-col justify-between cursor-pointer font-semibold text-left"} key={i} onClick={() => SetSelectedTier(prod.Detalle, prod.Precio, prod.Descripcion)}>
                                                <p className="tracking-normal leading-4 h-[60%] text-[14px] xl:text-[18px]">{prod.Descripcion}</p>
                                                <p className="h-[30%] text-center">$ {prod.Precio}</p>
                                            </div>
                                        ))}

                                    </div>
                                </div> :
                                <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
import React, { useEffect } from "react";
import CategoriaBar from "./CategoriaBar";
import MidPage from "./MidPage";
import TopBar from "./TopBar";

export default function DomicilioSite() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <TopBar />
            <CategoriaBar />
            <MidPage />
        </div>
    )
}
import { useEffect } from "react";
import CategoriaBar from "./CategoriaBar";
import TopBar from "./TopBar";

export default function Question() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <TopBar />
            <CategoriaBar />
            <div className="bg-white 2xl:w-[90%] rounded-2xl shadow-2xl mx-auto mt-5 p-5">
                <div>
                    <h3 className=" text-violet-600 font-bold text-[25px] underline mb-5">Preguntas Frecuentes</h3>

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿COMO HACER UN PEDIDO?</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Podes visitar nuestra web y elegir uno o mas artículos que podras ir subiendo al Carrito de
                            compras. Al finalizar tu selección, desde el Carrito podes completar todos los datos
                            necesarios para el envio y luego seleccionar la forma de pago.
                            También, podes contactarnos al WhatsApp por consultas (549) 351 2075356.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿QUÉ DATOS NECESITAMOS PARA EL ENVIO DE TU REGALO?</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">En general los datos básicos de la persona agasajada como nombre completo, dirección , dia y
                            hora de entrega, Saludo para la Tarjeta del regalo y tus datos de contacto para estar
                            conectados por cualquier duda.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿CUANDO DEBO AGENDAR MI PEDIDO?</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Como son Regalos que se preparan con productos frescos o de acuerdo a lo requerido por
                            el cliente, siempre se deben solicitar hasta el dia anterior a la entrega.
                            Podemos atender urgencias, pero dependerá de la agenda del dia. Contactanos por
                            WhatsApp y coordinaremos la mejor opción.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿CUANDO SE ENTREGA MI PEDIDO?</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Se realizan entregas todos los días del año, incluidos fines de semana y feriados ( excepto
                            este indicado en la web). Siempre debes indicar el dia y hora aproximada de entrega.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿CÓMO PUEDO PAGAR MI REGALO?</h3>
                        <div className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Estas son las opciones de pago:
                            <ul className=" list-decimal list-inside ml-[50px]">
                                <li>Pago por TRANSFERENCIA BANCARIA</li>
                                <li>Pago desde la WEB con Tarjetas de Credito/ Debito / Rapipago / PagoFacil y más…</li>
                                <li>Si vivís fuera de Argentina ( y no tenes tarjetas o cuenta en Bancos de Argentina) podes realizar
                                    el pago con estas opciones:
                                    <ul className=" list-disc list-inside ml-[50px]">
                                        <li>Western Union</li>
                                        <li>PAYPAL</li>
                                        <li>Link de pago con Tarjetas de crédito</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">NECESITO UN REGALO PERSONALIZADO:</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Hay en la web varias opciones pero podes contactarnos por whatsapp y solicitar o coordinar adicionales
                            o alguna opción especial.
                            Lo IMPORTANTE es que consideres un plazo mínimo de 48-72 hs hábiles ( el Taller NO trabaja Sábados y
                            Domingos) para los regalos personalizados.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">ENVIOS A DOMICILIO:</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">Todos nuestros servicios incluyen el ENVIO A DOMICILIO, también los REGALOS EMPRESARIALES.
                            Estos envios son SIN COSTO dentro de AV CIRCUNVALACION y fuera de esta área tienen costo adicional
                            según la distancia.
                            CONSULTAR el Costo de envios a otras Localidades y provincias.</p>
                    </div>

                    <hr />

                    <div className="mt-3 mb-3">
                        <h3 className=" text-violet-600 font-semibold text-[20px] antialiased">¿NECESITAS REGALOS EMPRESARIALES?</h3>
                        <p className="text-[18px] text-left leading-relaxed tracking-normal font-serif">En el sector de REGALOS EMPRESARIALES de la WEB encontraras las diferentes líneas de Regalos con
                            variedad de artículos.
                            No tienen precio porque tenemos un Servicio especial de COTIZACIONES a Empresas en función de la
                            cantidad de regalos, si llevan logo o detalles especiales.
                            Solicita una cotización por WhastApp o mail, indicando tipo de artículos, cantidad, fecha de entrega que
                            necesitan, etc y el área de Atención a Empresas se pondrá en contacto a la brevedad.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        //baseUrl: "http://localhost:3001"
        baseUrl: "https://api.imperial-regalos.com.ar"
        //baseUrl: ""
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => "/productos",
            providesTags: ["Tasks"],
        }),
        getOneProductByID: builder.query({
            query: (id) => `/productos?id=${id}`,
        }),
        getProductsByType: builder.query({
            query: (type) => `/productos?type=${type}`,
            providesTags: ["Tasks"],
        }),
        getProductsByCategory: builder.query({
            query: (category) => `/productos?category=${category}`,
            providesTags: ["Tasks"],
        }),
        createProduct: builder.mutation({
            query: (newTask) => ({
                url: "/Productos/AddProd",
                method: "POST",
                credentials: "include",
                body: newTask,
            }),
            invalidatesTags: ["Tasks"],
        }),
        editProduct: builder.mutation({
            query: (updateTask) => ({
                url: `/Productos/edit/${updateTask.id}`,
                method: "PUT",
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
                body: updateTask,
            }),
            invalidatesTags: ["Tasks"]
        }),
        deleteTask: builder.mutation({
            query: (id) => ({
                url: `/Productos/delete/${id}`,
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
                method: "DELETE",
            }),
            invalidatesTags: ["Tasks"]
        }),
        verifyToken: builder.mutation({
            query: (data) => ({
                url: "/User/signIn",
                method: "POST",
                body: data,
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            })
        }),
        getToken: builder.query({
            query: () => ({
                url: "/User/refresh",
                credentials: "include"
            }),
        }),
        CreatePedido: builder.mutation({
            query: (newTask) => ({
                url: "/Pedidos/AddPedido",
                method: "POST",
                body: newTask,
            }),
        }),
        GetPedido: builder.query({
            query: () => ({
                url: "/Pedidos/Pedidos",
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
                providesTags: ["Pedido"],
            }),
        }),
        GetIDPedido: builder.query({
            query: (id) => ({
                url: `/Pedidos/Pedidos?id=${id}`,
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
            })
        }),
        EditPedidoVisto: builder.mutation({
            query: (updateTask) => ({
                url: `/Pedidos/edit/${updateTask.id}`,
                method: "PUT",
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
                body: updateTask,
            }),
            invalidatesTags: ["Pedido"]
        }),
        DeletePedido: builder.mutation({
            query: (id) => ({
                url: `/Pedidos/delete/${id}`,
                headers: {
                    "x-access-token": localStorage.getItem("token")
                },
                method: "DELETE",
            }),
            invalidatesTags: ["Pedido"],
        }),
        MakePayment: builder.mutation({
            query: (newTask) => ({
                url: "/Pay/crear_compra",
                method: "POST",
                body: newTask,
            }),
        }),
    }),
})

export const { useGetProductsQuery, useGetOneProductByIDQuery, useGetProductsByCategoryQuery, useGetProductsByTypeQuery,
    useCreateProductMutation, useDeleteTaskMutation, useEditProductMutation, useVerifyTokenMutation, useGetTokenQuery,
    useCreatePedidoMutation, useGetPedidoQuery, useGetIDPedidoQuery, useEditPedidoVistoMutation, useDeletePedidoMutation,
    useMakePaymentMutation } = apiSlice;
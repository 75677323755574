import React from "react";
import EmpresarialMenu from "./EmpresarialMenu.jsx"
import CardList from "./CardList.jsx";
import { useSearchParams } from "react-router-dom";

export default function MidPage() {
    const [getQuery] = useSearchParams();
    let category = getQuery.get("category");

    function CheckType() {
        switch (category) {
            case "accesorio":
                return "Accesorios para Vino";
            case "agendas":
                return "Agendas / Portfolios";
            case "outside":
                return "Aire Libre / Uso Personal";
            case "asado":
                return "Asado";
            case "botellas":
                return "Botellas / Jarros";
            case "canastas":
                return "Canastas / Delikatessen";
            case "vinos":
                return "Estuches Vino / Copas";
            case "boligrafos":
                return "Boligrafos";
            case "escritiorio":
                return "Escritiorio";
            case "decoracion":
                return "Decoracion / Hogar";
            case "marroquineria":
                return "Marroquineria";
            case "mates":
                return "Mates";
            case "mujer":
                return "Mujer";
            case "elojes":
                return "Relojes y Portalapices";
            case "tecnologia":
                return "Tecnologia y PC";
            case "varios":
                return "Varios";
            default:
                return "Regalos Empresariales";
        }
    }

    return (
        <div className="w-full">
            <div className="xl:w-[100%] 2xl:w-3/4 mx-auto flex">
                <EmpresarialMenu />
                <div>
                    <h3 className="text-[25px] text-violet-600 font-bold">{CheckType()}</h3>
                    <CardList />
                </div>

            </div>
        </div>
    )
}
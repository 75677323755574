import React from "react";
import LogoImperial from "../Assets/LogoBlanco.png"
import ReactWhatApp from "react-whatsapp"
import WhatIcon from "../Assets/Icons/whatsapp.png"
import InstaIcon from "../Assets/Icons/instagram-logo.png"
import FaceIcon from "../Assets/Icons/facebook.png"
import GmailIcon from "../Assets/Icons/gmail.png"
import QuestonIcon from "../Assets/questionIcon.png"
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <div className="absolute inset-x-0  sm:bg-gray-500 sm:mt-10 w-auto -z-[0]">

            <div className="flex justify-between sm:hidden h-[50px] w-[100%]">
                <div className="flex">
                    <Link to="/Preguntas"><img src={QuestonIcon} alt="what_Icon" className="w-[50px] h-[50px]" /></Link>
                    <h3 className=" font-semibold my-auto text-[20px]">Informacion</h3>
                </div>
                <div className="flex">
                    <h3 className=" font-semibold my-auto text-[20px]">Contacto:</h3>
                    <ReactWhatApp number="5493512075356"><img src={WhatIcon} alt="what_Icon" className="w-[50px] h-[50px]" /></ReactWhatApp>
                </div>

            </div>

            <div className="flex justify-center xl:w-[100%] 2xl:w-3/4 mx-auto h-[100px] max-sm:hidden">

                <div className=" max-md:hidden md:basis-1/4 flex border-r-[1.5px] border-black">
                    {/* Logo */}
                    <Link to="/" className="flex">
                        <img src={LogoImperial} alt="Logo_Imperial" className="
                        md:h-[55%] xl:h-[65%] 2xl:h-[65%] 
                        my-auto mx-auto 2xl:mx-0" />
                    </Link>
                </div>

                <div className="flex flex-col max-md:basis-[70%] md:basis-2/4 border-r-[1.5px] border-black">
                    <div className=" flex justify-evenly m-auto w-full font-bold text-white">
                        <h3 className=" md:text-[15px] xl:text-[17px] 2xl:text-[17px] 
                        basis-1/2 text-center border-r-[1.5px] border-black">Contacto</h3>
                        <h3 className=" md:text-[15px] xl:text-[17px] 2xl:text-[17px]
                        basis-1/2 text-center">Redes</h3>
                    </div>
                    {/* Datos */}
                    <div className="flex h-full justify-evenly">

                        <div className="flex flex-col justify-around border-r-[1.5px] border-black  basis-1/2">
                            <div className="flex  xl:ml-3 2xl:ml-3">
                                <img src={WhatIcon} alt="What_icon" className="
                                h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:h-7 2xl:h-7 xl:w-7 2xl:w-7" />
                                <ReactWhatApp number="5493512075356" className="
                                md:ml-0 xl:ml-2 2xl:ml-2
                                md:text-[13px] xl:text-[18px] 2xl:text-[18px] 
                                font-bold  hover:underline text-white
                                cursor-pointer">{"("}+549{")"} 351 2075356</ReactWhatApp>
                            </div>

                            <div className="flex  xl:ml-3 2xl:ml-3">
                                <img src={GmailIcon} alt="Gmail_Logo" className="
                                h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:w-6 xl:h-6 2xl:h-6 2xl:w-7"/>
                                <span className="
                                md:ml-0 xl:ml-2 2xl:ml-2 
                                md:text-[13px] xl:text-[18px] 2xl:text-[18px] 
                                text-white hover:underline 
                                cursor-pointer">
                                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=imperial.regalos@gmail.com" target="_blank">imperialregalos@gmail.com</a>
                                </span>
                            </div>
                        </div>
                        {/* Redes */}
                        <div className=" flex flex-col justify-around  basis-1/2">
                            <div className="flex xl:ml-3 2xl:ml-3">
                                <img src={FaceIcon} alt="Facebook_Logo" className="
                                h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:h-7 2xl:h-7 xl:w-7 2xl:w-7" />
                                <span className="
                                md:ml-0 xl:ml-2 2xl:ml-2 
                                md:text-[13px] xl:text-[18px] 2xl:text-[18px] 
                                text-white hover:underline text-nowrap
                                 cursor-pointer"><a target="_blank" href="https://www.facebook.com/imperialreg/" title="example">imperialreg/Imperialempresas</a></span>
                            </div>

                            <div className="flex xl:ml-3 2xl:ml-3">
                                <img src={InstaIcon} alt="Insta_Logo" className="
                                h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:h-7 2xl:h-7 xl:w-7 2xl:w-7" />
                                <span className="
                                md:ml-0 xl:ml-2 2xl:ml-2 
                                md:text-[13px] xl:text-[18px] 2xl:text-[18px]
                                text-white hover:underline
                                 cursor-pointer"><a target="_blank" href="https://www.instagram.com/imperial.regalos/" title="example">imperial.regalos</a></span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Nosotros */}
                <div className=" max-md:basis-[30%] md:basis-1/4 flex flex-col justify-around text-[20px] font-bold text-white">
                    <div className="flex justify-center cursor-pointer">
                        <Link to="/Nosotros">
                            <h3 className=" hover:underline text-center
                            max-sm:text-[14px] sm:text-[16px] md:text-[18px]
                            ">¿Quienes Somos?</h3>
                        </Link>
                    </div>
                    <div className="flex justify-center cursor-pointer">
                        <Link to="/Preguntas">
                            <h3 className=" hover:underline text-center
                            max-sm:text-[14px] sm:text-[16px] md:text-[18px]
                            ">Preguntas Frecuentes</h3>
                        </Link>
                    </div>

                </div>


            </div>
            <div className="hidden sm:flex">
                <h2 className="md:text-left lg:text-center text-[12px] text-white text-nowrap lg:ml-[40%]">IMPERIAL REGALOS. 2024 - 2024. Todos los derechos reservados</h2>
                <h2 className="text-right text-[12px] text-white text-nowrap ml-auto mr-[10px]">Pagina creada por: <Link to="https://github.com/DarkWoodsHunter" className="hover:underline">DarkWoodHunter</Link></h2>
            </div>

        </div>
    )
}
import TopBar from "../TopBar"
import CategoriaBar from "../CategoriesBar"
import { useSearchParams } from "react-router-dom";
import { useGetIDPedidoQuery } from "../../../AppStore/ApiSlice";
import { useEffect, useState } from "react";
import { useDeletePedidoMutation, useEditPedidoVistoMutation } from "../../../AppStore/ApiSlice";
import { useNavigate } from "react-router-dom";

export default function PedidoCard() {

    const [getQuery] = useSearchParams();
    let id = getQuery.get("id");

    const { data: Pedido, isError, isLoading, error } = useGetIDPedidoQuery(id);
    const [deleteTasks] = useDeletePedidoMutation();
    const [editVisto] = useEditPedidoVistoMutation();

    const [productoPedido, setProductoPedido] = useState();
    const [visibleDelete, setVisibleDelete] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        if (!isLoading) {
            const GetPedido = Pedido.EnviarPedido
            const TranslatePedido = JSON.parse(GetPedido)
            setProductoPedido(TranslatePedido)
        }

    }, [isLoading])

    if (isLoading) return <></>


    function TranslateDate(e, HoraNo) {
        const date = new Date(e);
        if (HoraNo) {
            const options = {
                weekday: "long",
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: "numeric",
                minute: "numeric"
            }
            return (date.toLocaleDateString("es-AR", options))
        } else {
            const options = {
                weekday: "long",
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }
            return (date.toLocaleDateString("es-AR", options))
        }
    }

    const Delete = (event) => {
        deleteTasks(event)
        setVisibleDelete(false)
        nav("/Admin/Registro")
    }
    const NoDelete = () => {
        setVisibleDelete(false)
    }
    const PrepareDelete = () => {
        setVisibleDelete(true)
    }

    const ComfirmarPedido = () => {
        const Visto = true;
        const id = Pedido._id;
        console.log(id)
        editVisto({ id, Visto })
        alert("Pedido Comfirmado")
    }

    function TranslateDateFechaEntrega(e) {
        const date = new Date(e);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: "UTC"
        }
        return (date.toLocaleDateString("es-AR", options))
    }


    return (
        <>
            <TopBar />
            <CategoriaBar />

            <div className="bg-white rounded-lg w-[85%] mt-5 mx-auto p-3">

                <h3 className="font-semibold mb-3 text-[20px]">Datos Comprador</h3>

                <div className="ml-3 text-[18px] border-2 border-red-600">
                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Nombre: </h3>
                        <h3 className="ml-2">{Pedido.NombreCliente}</h3>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Pedido Realizado: </h3>
                        <h3 className="ml-2">{TranslateDate(Pedido.FechaCreacion, true)}</h3>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Correo Electronico: </h3>
                        <h3 className="ml-2">{Pedido.Email}</h3>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Telefono: </h3>
                        <h3 className="ml-2">{Pedido.Telefono}</h3>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Articulos Comprados: </h3>
                        <div className="flex">
                            {!productoPedido ? "" : productoPedido.map((ped, i) => {
                                return (
                                    <div key={i} className="">
                                        <div className="ml-2">
                                            {ped.Tier}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Tipo de Delivery: </h3>
                        <h3 className="ml-2">{Pedido.ZonaEntrega}</h3>
                    </div>

                    <div className="flex mb-2">
                        <h3 className=" underline font-semibold">Precio: </h3>
                        <h3 className="ml-2">{Pedido.CostoTotal}</h3>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg w-[85%] mt-5 mx-auto p-3">
                <h3 className="font-semibold mb-3 text-[20px]">Datos Agasajado</h3>
                <div className="ml-3 text-[18px] border-2 border-red-600">
                    <div className="flex">
                        <h3 className=" underline font-semibold">Nombre: </h3>
                        <h3 className="ml-2">{Pedido.NombreAgasajado}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Fecha a Entregar: </h3>
                        <h3 className="ml-2">{TranslateDateFechaEntrega(Pedido.FechaEntrega, false)}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Hora a Entregar: </h3>
                        <h3 className="ml-2">{Pedido.HoraEntrega}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Barrio: </h3>
                        <h3 className="ml-2">{Pedido.BarrioAgasajado}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Direccion: </h3>
                        <h3 className="ml-2">{Pedido.DireccionAgasajado}</h3>
                    </div>

                    {Pedido.PisoAgasajado == null || Pedido.PisoAgasajado == "" ? "" :
                        <div className="flex">
                            <h3 className=" underline font-semibold">Piso: </h3>
                            <h3 className="ml-2">{Pedido.PisoAgasajado}</h3>
                        </div>
                    }

                    {Pedido.DepartamentoAgasajado == null || Pedido.DepartamentoAgasajado == "" ? "" :
                        <div className="flex">
                            <h3 className=" underline font-semibold">Departamento: </h3>
                            <h3 className="ml-2">{Pedido.DepartamentoAgasajado}</h3>
                        </div>
                    }

                    <div className="flex">
                        <h3 className=" underline font-semibold">Telefono: </h3>
                        <h3 className="ml-2">{Pedido.TelefonoAgasajado}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Dedicatoria: </h3>
                        <h3 className="ml-2">{Pedido.Dedicatoria}</h3>
                    </div>

                    <div className="flex">
                        <h3 className=" underline font-semibold">Comentario: </h3>
                        <h3 className="ml-2">{Pedido.Comentario}</h3>
                    </div>
                </div>


            </div>

            <div className="w-[20%] mx-auto flex mt-5">
                <button className=" bg-slate-500 hover:bg-slate-700 rounded-lg pointer w-[100%] mx-1 text-white text-[23px] font-bold" onClick={() => ComfirmarPedido()}>Confirmar</button>
                <button className=" bg-red-500 hover:bg-red-700 rounded-lg pointer w-[100%] mx-1 text-white text-[23px] font-bold" onClick={() => PrepareDelete()}>Eliminar</button>
            </div>

            <div className={visibleDelete ? "bg-blue-500 fixed top-[50%] left-[50%] w-[500px] h-[150px] rounded-xl flex flex-col" : "hidden"}>
                <h3 className=" text-center text-white mt-2">¿Esta Seguro que quiere borrar este registro?</h3>
                <div className="mt-auto mb-5 flex justify-center">
                    <button className=" bg-green-500 hover:bg-green-700 w-[200px] h-[30px] rounded-lg mr-2" onClick={() => NoDelete()}>No</button>
                    <button className=" bg-red-500 hover:bg-red-700 w-[200px] h-[30px] rounded-lg ml-2" onClick={() => Delete(Pedido._id)}>Si</button>
                </div>
            </div>
        </>
    )
}
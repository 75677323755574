import React, { useEffect, useState, useRef } from "react";
import { useCreatePedidoMutation, useMakePaymentMutation } from "../../AppStore/ApiSlice";
import WhatIcon from "../../Assets/whatIcon.png"
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import ReactWhatsapp from "react-whatsapp";
import emailjs from '@emailjs/browser';

import TrashCanIcon from "../../Assets/Icons/trash-can.png"


export default function CarritoProd() {

    initMercadoPago('APP_USR-2ebb3475-3081-40e7-a59e-f9ec418c1a15', {
        locale: "es-AR",
    });
    const [preferenceID, setPreferenceID] = useState();

    const [pedidos, setPedidos] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    const [finalPrice, setFinalPrice] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [NombreInfo, setNombreInfo] = useState("");
    const [formError, setFormError] = useState({});

    const [CreatePedido, { isSuccess }] = useCreatePedidoMutation();
    const [MakePayment] = useMakePaymentMutation();

    const sendForm = useRef();



    const GetPedido = localStorage.getItem("Pedido");
    const TranslatePedido = JSON.parse(GetPedido);
    let TempPrice = 0;


    useEffect(() => {
        if (!isLoading) {
            setPedidos(TranslatePedido);
            setIsloading(true);
        }
    }, [TranslatePedido, isLoading]);

    useEffect(() => {
        if (pedidos) {
            for (let i = 0; i < pedidos.length; i++) {
                TempPrice = TempPrice + (parseInt(pedidos[i].PrecioUnidad) * parseInt(pedidos[i].Cantidad));
            }
        }

        setTotalPrice(TempPrice)
        setFinalPrice(TempPrice)
    }, [pedidos]);

    useEffect(() => {
        if (isSuccess) {
            HandleBuy();
        }
    }, [isSuccess])

    function Delete(e) {
        const temp = [...pedidos];
        temp.splice(e.target.key, 1);
        localStorage.setItem("Pedido", JSON.stringify(temp));
        setPedidos(temp);
    }

    function HandleChange(e) {
        let tempPrice = parseInt(e.target.value);
        setFinalPrice(totalPrice + tempPrice)
    }

    function CheckForm(e) {
        let err = {};
        if (pedidos == null || pedidos == "") {
            err.Carrito = "El carrito esta Vacio"
        }
        if (e.target.elements.NombreComprador.value.trim() == "") {
            err.NombreCliente = "Nombre Requerido*";
        }
        if (e.target.elements.ApellidoComprador.value.trim() == "") {
            err.ApellidoCliente = "Apellido Requerido*";
        }
        if (e.target.elements.EmailComprador.value.trim() == "") {
            err.Email = "Correo Requerido*";
        }
        if (e.target.elements.TelefonoComprador.value.trim() == "") {
            err.Telefono = "Telefo Requerido*";
        }
        if (e.target.elements.FechaEntrega.value == "") {
            err.Date = "Fecha Requerida*";
        }
        if (e.target.elements.HoraEntrega.value == "Seleccione hora de Entrega") {
            err.Hora = "Hora de Entrega Requerida Requerida*";
        }
        if (e.target.elements.NombreAgasajado.value.trim() == "") {
            err.NombreAgasajado = "Nombre Requerido*";
        }
        if (e.target.elements.ApellidoAgasajado.value.trim() == "") {
            err.ApellidoAgasajado = "Apellido Requerido*";
        }
        if (e.target.elements.DireccionAgasajado.value.trim() == "") {
            err.DireccionAgasajado = "Direccion Requerida*";
        }
        if (e.target.elements.BarrioAgasajado.value.trim() == "") {
            err.BarrioAgasajado = "Barrio Requerido*";
        }
        if (e.target.elements.TelefonoAgasajado.value.trim() == "") {
            err.TelefonoAgasajado = "Telefono Requerido*";
        }
        setFormError({ ...err })

        return Object.keys(err).length < 1;
    }

    const OnHandleSummit = async (e) => {
        e.preventDefault();
        let formIsValid = CheckForm(e);
        if (!formIsValid) {
            return
        }

        emailjs.sendForm(process.env.REACT_APP_SERVICIOEMAILJS, process.env.REACT_APP_TEMPLATEEMAILJS, e.target, {
            publicKey: process.env.REACT_APP_EMAILPUBLICKEY,
        });
        //Datos Producto
        const EnviarPedido = JSON.stringify(pedidos);
        const CostoEnvio = e.target.elements.ZonaEntregaComprador.value;
        const CostoTotal = totalPrice;
        //Datos Comprado
        const NombreCliente = e.target.elements.NombreComprador.value.trim() + " " + e.target.elements.ApellidoComprador.value.trim();
        const Email = e.target.elements.EmailComprador.value.trim();
        const Telefono = e.target.elements.TelefonoComprador.value.trim();
        const ZonaEntrega = e.target.elements.ZonaEntregaComprador.value;
        //Datos Agasajado
        const FechaEntrega = e.target.elements.FechaEntrega.value;
        const HoraEntrega = e.target.elements.HoraEntrega.value;
        const NombreAgasajado = e.target.elements.NombreAgasajado.value.trim() + " " + e.target.elements.ApellidoAgasajado.value.trim();
        const DireccionAgasajado = e.target.elements.DireccionAgasajado.value.trim();
        const PisoAgasajado = e.target.elements.PisoAgasajado.value.trim();
        const DepartamentoAgasajado = e.target.elements.DepartamentoAgasajado.value.trim();
        const BarrioAgasajado = e.target.elements.BarrioAgasajado.value.trim();
        const TelefonoAgasajado = e.target.elements.TelefonoAgasajado.value.trim();
        const Dedicatoria = e.target.elements.Dedicatoria.value.trim();
        const Comentario = e.target.elements.ComentarioExtra.value.trim();

        setNombreInfo(NombreCliente);
        CreatePedido({ EnviarPedido, CostoEnvio, CostoTotal, NombreCliente, Email, Telefono, ZonaEntrega, FechaEntrega, HoraEntrega, NombreAgasajado, DireccionAgasajado, PisoAgasajado, DepartamentoAgasajado, BarrioAgasajado, TelefonoAgasajado, Dedicatoria, Comentario })
        localStorage.removeItem("Pedido");
    }


    const CreatePreference = async () => {
        try {
            let title = pedidos.map(ped => `${ped.Name} (x${ped.Cantidad})`).join(", ");
            let quantity = 1;
            /*for (let i = 0; i < pedidos.length; i++) {
                quantity = quantity + parseInt(pedidos[i].Cantidad);
            }*/
            let unit_price = finalPrice;
            const response = await MakePayment({ title, quantity, unit_price })
            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    }
    const HandleBuy = async () => {
        const id = await CreatePreference();
        if (id) {
            setPreferenceID(id);
        }
    };


    return (
        <div>
            <div className="flex flex-col bg-white shadow-xl rounded-xl min-h-[10px] mx-5 mt-2">
                {/* Carrito */}
                {pedidos == null || pedidos == "" ?
                    <div>
                        <p>El Carrito esta Vacio</p>
                        <span>{formError.carrito}</span>
                    </div>
                    :
                    <div className="flex flex-col bg-white shad rounded-xl min-h-[10px] mx-5 mt-2">
                        <table className=" ">

                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio Unidad</th>
                                    <th>Cantidad</th>
                                    <th>Precio Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pedidos.map((e, i) => {
                                    return (
                                        <tr className=" bg-slate-300 h-[50px]  mx-2 rounded-xl border-t-[1.5px] border-b-[1.5px] border-black" key={i}>
                                            <th>
                                                <h3>{e.Name}</h3>
                                            </th>

                                            <th>
                                                <h3>${e.PrecioUnidad}</h3>
                                            </th>

                                            <th>
                                                <h3>{e.Cantidad}</h3>
                                            </th>

                                            <th>
                                                <h3>${e.PrecioUnidad * e.Cantidad}</h3>
                                            </th>

                                            <th>
                                                <div className="flex">
                                                    <img src={TrashCanIcon} className="pointer cursor-pointer h-[20px] w-[20px]" onClick={(e) => { Delete(e) }} />
                                                    <span className="hover:underline cursor-pointer ml-[10px]" onClick={(e) => { Delete(e) }}>ELIMINAR</span>
                                                </div>


                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th><div className="ml-auto mr-[25%]">
                                        <h3 className="">Total: ${totalPrice}</h3>
                                    </div>
                                    </th>
                                    <th></th>
                                </tr>
                            </tbody>
                        </table>



                    </div>
                }
            </div>

            <form onSubmit={OnHandleSummit} ref={sendForm}>
                {/* Datos Del Comprador */}
                <div className="flex flex-col bg-white shadow-xl rounded-xl min-h-[10px] mx-5 mt-5 py-[30px]">
                    <h2 className="mx-5 font-bold text-[19px]">Datos Del Comprador</h2>

                    <div className="flex mx-5 justify-between">

                        <div className="flex flex-col w-[48%]">
                            <h4>Nombre*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="NombreComprador" type="text" placeholder="Insertar Nombre" maxLength={15}/>
                            <span className=" text-red-600 text-[15px]">{formError.NombreCliente}</span>
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Apellido*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="ApellidoComprador" type="text" placeholder="Insertar Apellido" maxLength={15}/>
                            <span className=" text-red-600 text-[15px]">{formError.ApellidoCliente}</span>
                        </div>

                    </div>

                    <div className="flex mx-5 justify-between">

                        <div className="flex flex-col w-[48%]">
                            <h4>Email*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="EmailComprador" type="text" placeholder="Inserte su Email" maxLength={50}/>
                            <span className=" text-red-600 text-[15px]">{formError.Email}</span>
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Telefono*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="TelefonoComprador" type="text" placeholder="Inserte un Telefono" maxLength={18}/>
                            <span className=" text-red-600 text-[15px]">{formError.Telefono}</span>
                        </div>

                    </div>

                    <div className="flex mx-5 justify-between">

                        <div className="flex flex-col w-[100%]">
                            <h4>Zona de Entrega</h4>
                            <select className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="ZonaEntregaComprador" type="text" onChange={(e) => HandleChange(e)}>
                                <option value="0">SIN COSTO: Envios en Cordoba Capital en Todos los Barrios dentro de Av. Circunvalacion</option>
                                <option value="1500">ADICIONAL $ 1500.00 - Arguello de Mujer Urbana hasta CPC Rafael Nuñez -R.MARTINOLI hasta rotonda H.MARTINEZ</option>
                                <option value="1500">ADICIONAL $ 1500.00 - R.Rojas dej CPC hasta H.Martinez. J.B Justo hasta GUIÑAZU. B°Los Boulevares. Zona Colectoras Av. Circunvalcion </option>
                                <option value="1500">ADICIONAL $ 1500.00 - B°hasta km4. Cno. S.Carlos/S.Antonio-Bº Sta Rosa- V.Sarsfield 4000-Villa Libertador, Sta Isabel I y alrededores</option>
                                <option value="1500">ADICIONAL $ 1500.00 - Barrios Don Bosco, Militar, Valle Escondido - Countries Zona FICO y alrededores</option>
                                <option value="1500">ADICIONAL $ 1500.00 - B. Palmar - 1 de Mayo anexo-Pque Dean Funes - Jose I.Diaz IV y V - B. Carcano - Cno Interfabricas.</option>
                                <option value="3000">ADICIONAL $ 3000.00 - B. Cabildo, Univ. Catolica - Sta Isabel II y III-Pque Futura - Docta- Cno S.Carlos /S.Antonio del km 4 al 7- Inaudi</option>
                                <option value="3000">ADICIONAL $ 3000.00 - D.Alvarez hasta 8000- R.Martinolli y R.ROJAS de H.Martinez hasta Av. Boderau - V.Warcalde.</option>
                                <option value="3000">ADICIONAL $ 3000.00 - B° Comercial -V.Sarsfield del 4000 al 7000-B.San Lorenzo- Ituzaingo - Ferreyra - R9 N- Villa Los Llanos -Aeropuerto.</option>
                                <option value="6500">ADICIONAL $ 6500.00 - Country Las Delicias, Los Carolinos, El Bosque y alrededores- Villa Allende-Saldan- Malvinas Arg.- Juarez Celman</option>
                                <option value="8500">ADICIONAL $ 8500.00 - Malagueño, Mendiolaza, La Calera, Colonia Tirolesa y alrededores</option>
                                <option value="12000">ADICIONAL $ 12000.00 - Alta Gracia, Carloz Paz, Rio Ceballos, Unquillo, Montecristo y alrededores</option>
                                <option value="15000">ADICIONAL $ 15000.00 - Rio Segundo, Pilar, Jesus Maria, Colonia Caroya, Rio 1</option>
                            </select>
                        </div>

                    </div>
                </div>

                {/* Datos Del Agasajado */}
                <div className="flex flex-col bg-white shadow-xl rounded-xl min-h-[10px] mx-5 mt-5 py-[30px]">
                    <h2 className="mx-5 font-bold text-[19px]">Datos del Agasajado</h2>

                    <div className="flex mx-5 justify-between">
                        <div className="flex flex-col w-[48%]">
                            <h4>Dia de Entrega*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" type="date" name="FechaEntrega" min={new Date().toJSON().slice(0, 10)} />
                            <span className=" text-red-600 text-[15px]">{formError.Date}</span>
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Hora de Entrega*</h4>
                            <select className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="HoraEntrega">
                                <option>Seleccione hora de Entrega</option>
                                <option>5:30</option>
                                <option>6:00</option>
                                <option>6:30</option>
                                <option>7:00</option>
                                <option>7:30</option>
                                <option>8:00</option>
                                <option>8:30</option>
                                <option>9:00</option>
                                <option>9:30</option>
                                <option>10:00</option>
                                <option>10:30</option>
                                <option>11:00</option>
                                <option>11:30</option>
                                <option>12:00</option>
                                <option>12:30</option>
                                <option>13:00</option>
                                <option>13:30</option>
                                <option>14:00</option>
                                <option>14:30</option>
                                <option>15:00</option>
                                <option>15:30</option>
                                <option>16:00</option>
                                <option>16:30</option>
                                <option>17:00</option>
                                <option>17:30</option>
                                <option>18:00</option>
                                <option>18:30</option>
                                <option>19:00</option>
                                <option>19:30</option>
                                <option>20:00</option>
                            </select>
                            <span className=" text-red-600 text-[15px]">{formError.Hora}</span>
                        </div>
                    </div>

                    <div className="flex mx-5 justify-between">
                        <div className="flex flex-col w-[48%]">
                            <h4>Nombre*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" type="text" name="NombreAgasajado" placeholder="Nombre del Agasajado" maxLength={15}/>
                            <span className=" text-red-600 text-[15px]">{formError.NombreAgasajado}</span>
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Apellido*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" name="ApellidoAgasajado" placeholder="Apellido del Agasajado" maxLength={15}/>
                            <span className=" text-red-600 text-[15px]">{formError.ApellidoAgasajado}</span>
                        </div>
                    </div>

                    <div className="flex mx-5 justify-between">
                        <div className="flex flex-col w-[48%]">
                            <h4>Direccion*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" type="text" name="DireccionAgasajado" placeholder="Direccion de la Entrega..." maxLength={30}/>
                            <span className=" text-red-600 text-[15px]">{formError.DireccionAgasajado}</span>
                        </div>

                        <div className="flex justify-between w-[48%]">
                            <div className="w-[49%]">
                                <h4>Piso</h4>
                                <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2 w-[100%]" type="text" name="PisoAgasajado" placeholder="Piso o Planta de la Entrega..." maxLength={15}/>
                            </div>
                            <div className="w-[50%]">
                                <h4>Departamento</h4>
                                <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2 w-[100%]" type="text" name="DepartamentoAgasajado" placeholder="Departamento de la Entrega..." maxLength={15}/>
                            </div>
                        </div>
                    </div>

                    <div className="flex mx-5 justify-between">
                        <div className="flex flex-col w-[48%]">
                            <h4>Barrio*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" type="text" name="BarrioAgasajado" placeholder="Barrio de la Entrega..." maxLength={15}/>
                            <span className=" text-red-600 text-[15px]">{formError.BarrioAgasajado}</span>
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Telefono*</h4>
                            <input className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] pl-2" type="text" name="TelefonoAgasajado" placeholder="Telefono del Agasajado..." maxLength={18}/>
                            <span className=" text-red-600 text-[15px]">{formError.TelefonoAgasajado}</span>
                        </div>
                    </div>

                    <div className="flex mx-5 justify-between">
                        <div className="flex flex-col w-[48%]">
                            <h4>Ingresar Dedicatoria</h4>
                            <textarea className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] min-h-[80px] pl-2" name="Dedicatoria" placeholder="Ingrese una Dedicatoria..." maxLength={150} />
                        </div>

                        <div className="flex flex-col w-[48%]">
                            <h4>Ingresar Comentario Extra</h4>
                            <textarea className=" bg-gray-200 rounded-md border-[1.5px] border-black h-[40px] min-h-[80px] pl-2" name="ComentarioExtra" placeholder="Ingrese algun comentario que le parezca relevante..." maxLength={150}/>
                        </div>
                    </div>

                    <button className=" bg-violet-500 hover:bg-purple-700 rounded-lg 
                    min-h-[50px] mx-auto mt-5 sm:w-[100px] md:w-[150px]
                    shadow-lg border-black border-[1.2px] text-white font-bold">
                        Confirmar Datos
                    </button>

                </div>
            </form>

            <div className={NombreInfo == "" ? "hidden" : "flex flex-col bg-white rounded-xl shadow-xl mx-5 mt-2 text-[20px]"}>

                <div className="flex flex-col bg-white mx-5 mt-2">
                    <div>
                        <div className="mb-5 mt-5 flex">Hola <h3 className="font-bold ml-1">{NombreInfo}</h3>!</div>
                    </div>

                    <h3 className="mb-3">Muchas Gracias por tu Compra</h3>
                    <div className="mb-3 flex">El importe total es <h3 className=" font-bold ml-1">${finalPrice}</h3></div>
                    <hr />
                    <h3>Estas son las opciones de pago:</h3>
                </div>

                <ol className="flex flex-col  bg-white mx-[50px] mt-2 max-md:text-[17px]">

                    <li className="mb-3 flex">1-  Pago por <h3 className=" font-bold ml-1">TRANSFERENCIA BANCARIA</h3></li>
                    <div className="flex max-md:flex-col
                     text-violet-500 font-bold 
                     max-md:mb-0 md:mb-3
                     ">
                        <div className=" ml-[75px] max-md:border-[1.5px] max-md:border-black">
                            <h3>BRUBANK</h3>
                            <h3>Titular: Viviana Alejandra Rivero</h3>
                            <h3>CBU: 1430001713002836120011</h3>
                            <h3>ALIAS: vivi.rivero</h3>
                        </div>

                        <div className=" max-md:ml-[75px] max-md:mt-4 max-md:border-[1.5px] max-md:border-black ml-[100px]">
                            <h3>BANCO GALICIA</h3>
                            <h3>Titular: Walter Correcher</h3>
                            <h3>CBU: 0070076430004016925037</h3>
                            <h3>ALIAS: IMPERIAL-REGALOS</h3>
                        </div>
                    </div>

                    <li className="mb-3 flex max-md:text-[17px]">
                        <text>
                            2-  Pago por <text className="font-bold">Tarjetas de Credito / Debito / Rapipago / PagoFacil y mas...</text>
                        </text>
                    </li>
                    <div className="mb-3 ml-[75px]  rounded-xl w-[50%]">
                        {preferenceID && <Wallet initialization={{ preferenceId: preferenceID }} />}
                    </div>

                    <li className="mb-3 flex">
                        <text className="text-justify">
                            3- Si vivis fuera de <text className="font-bold">Argentina </text>{"("} y no tienes tarjetas o cuenta en Banco de Argentina{")"} podes realizar el pago con estas opciones:
                        </text>
                    </li>
                    <ul className="list-disc list-inside ml-[75px] mb-3">
                        <li>Western Union</li>
                        <li>PAYPAL</li>
                        <li>Link de pago con  Tarjeta de Credito</li>
                    </ul>
                    <div className="flex bg-white shadow-xl rounded-xl min-h-[10px] mt-2">
                        <h2>CONTACTANOS AQUI: </h2>
                        <img src={WhatIcon} alt="What_icon" className="h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:h-7 2xl:h-7 xl:w-7 2xl:w-7 ml-2 mt-1" />
                        <ReactWhatsapp number="5493512075356" className=" ml-2 hover:underline">5493512075356</ReactWhatsapp>
                    </div>
                </ol>


                <hr />
                <div className="flex justify-center mt-5 max-md:text-[17px]">
                    <text className=" bg-white shadow-xl rounded-xl min-h-[10px]">¿Tenes dudas? ¿Queres consultarnos por otros temas?<text className="font-bold"> Contactanos aqui:</text>
                        <div className="flex md:justify-center">
                            <img src={WhatIcon} alt="What_icon" className="h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5 xl:h-7 2xl:h-7 xl:w-7 2xl:w-7 ml-2" />
                            <ReactWhatsapp number="5493512075356" className=" ml-2 hover:underline">5493512075356</ReactWhatsapp>
                        </div>
                    </text>
                </div>
            </div>


        </div>
    )

}
import React from "react";
import SlickComponent from "./Slick";
import { Link } from "react-router-dom";
import TopBar from "./TopBar";

import DomicilioImg from "../Assets/MainDomicilio.jpeg"
import EmpresarialImg from "../Assets/MainEmpresarial.jpeg"
import { useEffect } from "react";

export default function MainPage() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div className="flex flex-col ">
            <TopBar />
            <SlickComponent />

            <div className="
             flex max-md:flex-col sm:flex m-auto">
                {/* Domicilio */}
                <Link to={"/Domicilio?type=domicilio"}>
                    <div className="bg-violet-600 hover:bg-violet-800 rounded-2xl  flex flex-col text-white shadow-xl max-md:mb-3 max-md:mr-0 mr-3">
                        <div className=" flex">
                            <h2 className=" 
                            mx-auto 
                            font-bold 
                            md:text-[20px] xl:text-[40px]">REGALOS A DOMICILIO</h2>
                        </div>
                        <div>
                            <img src={DomicilioImg} alt="Domicilio_main" className=" 
                            rounded-2xl 
                            md:mx-0 md:mb-0 xl:mx-[15px] 2xl:mx-[30px] xl:mb-[15px] 2xl:mb-[30px]
                            xl:w-[512px] xl:h-[384px] 2xl:w-[550px] 2xl:h-[412px]" />
                        </div>

                    </div>
                </Link>

                {/* Empresarial */}
                <Link to={"/Empresarial?type=empresarial"}>

                    <div className="bg-purple-600 hover:bg-purple-800 rounded-2xl flex flex-col text-white shadow-xl max-md:ml-0 ml-3">
                        <div className=" flex">
                            <h2 className="
                            mx-auto 
                            font-bold 
                            md:text-[20px] xl:text-[40px]">REGALOS EMPRESARIALES</h2>
                        </div>
                        <div>
                            <img src={EmpresarialImg} alt="Domicilio_main" className="
                            rounded-2xl 
                            md:mx-0 md:mb-0 xl:mx-[15px] 2xl:mx-[30px] xl:mb-[15px] 2xl:mb-[30px]
                            xl:w-[512px] xl:h-[384px] 2xl:w-[550px] 2xl:h-[412px]" />
                        </div>
                    </div>
                </Link>

            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { useVerifyTokenMutation } from "../../AppStore/ApiSlice";
import { useLocation } from "react-router-dom";

import logoIcon from "../../Assets/logoOficialHori.png"
import useAuth from "../../Hooks/useAuth.js";


export default function AdmTopBarLog() {

    const Navigation = useNavigate();
    const loc = useLocation();
    const { setAuth } = useAuth();

    const [VerifyUser, { data: Tok, isLoading, isError, error, isSuccess }] = useVerifyTokenMutation();
    const [loginError, setLoginError] = useState("");
    const [NombreUsuario, setNombreUsuario] = useState();
    const [Contrasena, setContrasena] = useState();

    useEffect(() => {
        if (isSuccess) {
            const accessToken = Tok.token;
            setAuth({ NombreUsuario, Contrasena, accessToken })

            Navigation("/Admin/log")
        }
        if (isError) {
            setLoginError("Usuario o Contraseña Incorrectos")
        }
    }, [isSuccess, isError])

    const OnHandleSummit = async (e) => {
        e.preventDefault();

        await VerifyUser({ NombreUsuario, Contrasena })
    }

    return (
        <div className="bg-gray-700 w-full h-20  border-black border">
            <div className="w-3/4 flex justify-between mx-auto">

                {/* Logo de la compania */}
                <div className="basis-1/3 flex">
                    <div className="cursor-pointer mx-auto">
                        <Link to={"/"}>
                            <img src={logoIcon} alt="Logo_img" className="h-20 w-25 p-2" />
                        </Link>

                    </div>
                </div>

                {/* input-buscador de productos */}
                {location.pathname == "/admin"
                    ? <form className="basis-1/3" onSubmit={OnHandleSummit}>
                        <div className="flex">
                            <div>
                                <span className="text-white font-bold text-sm">Nombre de Usuario</span>
                                <input type="text" name="User" placeholder='Insertar Usurio' className=" flex-auto rounded-full px-2 bg-gray-200 border-0 w-[275px] h-6 mx-2" onChange={(e) => setNombreUsuario(e.target.value)} />
                            </div>
                            <div>
                                <span className="text-white font-bold text-sm">Contraseña</span>
                                <input type="password" name="Clave" placeholder='Contraseña.' className=" flex-auto rounded-full px-2 bg-gray-200 border-0 w-[275px] h-6 mx-2" onChange={(e) => setContrasena(e.target.value)} />
                            </div>
                            <button className="bg-slate-600 hover:bg-slate-900 w-[200px] h-[25px] my-auto rounded-full text-white font-bold text-sm">Iniciar</button>
                        </div>
                        {loginError == "" ? <></> : <span className="text-red-500 font-semibold ml-5 underline decoration-wavy">{loginError}</span>}
                    </form>

                    :
                    <div>
                        <h3 className="flex text-white text-[20px] mt-5">Bienvenido Usuario<p className="ml-1 font-bold">IMPERIAL</p>!</h3>
                    </div>}

            </div>
        </div>
    )
}
import React from "react"
import { Link } from "react-router-dom";


export default function AdmTopBarLog() {

    return (
        <div className="bg-gray-700 w-full border-black border">
            <div className="w-3/4 flex justify-between mx-auto">
                <div className="flex flex-row w-full shadow-2xl">
                    {/*Desayunos*/}
                    <Link to={"/admin/log"} className="bg-gray-600 hover:bg-orange-800 basis-1/2 h-10 mx-1 pt-1 rounded-l-lg cursor-pointer">
                        <p className="pr-2 text-white tracking-wide uppercase text-2xl text-center">Productos</p>
                    </Link>
                    {/*Regalos Empresariales*/}
                    <Link to={"/Admin/Registro"} className="bg-gray-600 hover:bg-orange-800 basis-1/2 h-10 mx-1 pt-1 rounded-r-lg cursor-pointer">
                        <p className="pl-2 text-white tracking-wide uppercase text-2xl text-center">Registro</p>
                    </Link>

                </div>

            </div>
        </div>
    )
}
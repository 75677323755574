import { useEffect } from "react";
import CategoriaBar from "./CategoriaBar";
import TopBar from "./TopBar";

export default function Nosotros() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <TopBar />
            <CategoriaBar />
            <div className="bg-white 2xl:w-[90%] rounded-2xl shadow-2xl mx-auto mt-5 p-5">
                <div>
                    <h3 className=" text-violet-400 font-bold text-[25px] underline">Quienes Somos</h3>
                    <p className="text-[20px] mt-[20px]">Somos un empresa dedicada al Diseño y Envio de Regalos a Domicilio, desde 1999.</p>
                    <p className="text-[20px] mt-[20px]">Contamos con 2 unidades de Negocio bien diferenciados:</p>

                    <div className=" mt-2 ml-[50px]">
                        <div className=" flex max-lg:flex-col">
                            <p className="text-violet-400 font-bold text-[20px] text-nowrap">REGALOS SORPRESA a DOMICILIO:</p>
                            <p className="text-[20px] ml-1">Desayunos, Aperitivos, Bombones, Vinos y Copas, Delikatessen y muchos mas...</p>
                        </div>
                        <div className=" flex max-lg:flex-col mt-5">
                            <p className="text-violet-400 font-bold text-[20px] text-nowrap">REGALOS EMPRESARIALES PERSONALIZADOS:</p>
                            <p className="text-[20px] ml-1"> Articulos de promoción con el LOGO de tu empresa o Emprendimiento para que tu marca sea reconocida junto a la calidad de tu servicio.</p>
                        </div>
                    </div>

                    <p className="text-[20px] mt-5">Te invitamos a descubrir y conocer todas las opciones de Regalos disponibles, recorriendo esta WEB.</p>
                    <h3 className="text-[20px] font-bold mt-3">Horario de Atencion:</h3>
                    <div className="text-[20px] font-bold flex flex-col">
                        <div className="mx-auto">
                            <h3>REGALOS A DOMICILIO: Lunes a Sábados de 8 a 20hs.</h3>
                            <h3>REGALOS EMPRESARIALES: Lunes a Viernes de 8 a 18hs.</h3>
                        </div>
                        <h3 className="mx-auto">** TODOS NUESTROS SERVICIOS SON ONLINE **</h3>
                        <h3 className="mx-auto text-violet-600">imperial-regalos.com.ar</h3>
                    </div>
                </div>
            </div>
        </>
    )
}
import Carrito from "./Carrito/Carrito";
import CategoriaBar from "./CategoriaBar";
import TopBar from "./TopBar";

export default function CarritoPath() {
    return (
        <>
            <div>
                <TopBar/>
                <CategoriaBar/>
                <Carrito/>
            </div>
        </>
    )
}
import React from "react";
import { Link } from "react-router-dom";
import { useGetOneProductByIDQuery } from "../AppStore/ApiSlice";
import { useSearchParams } from "react-router-dom";
import loadigGif from "../Assets/Loading.gif"

export default function Card({ Producto }) {

    const { data: prod, isError, isLoading, error } = useGetOneProductByIDQuery(Producto._id);

    const [getQuery] = useSearchParams();
    let type = getQuery.get("type");
    let category = getQuery.get("category");


    if (isError) return <div>Error: {error.message}</div>;

    function CheckType() {
        if (type && type == "domicilio" || category && category.includes("desayuno&merienda", "aperitivos", "infantil", "brindis", "bombones", "flores", "nacimiento", "delikatessen", "vino&copas", "canasta&baules", "especiales", "opcionesparaagregar")) {
            return `/Domicilio/Producto?id=${Producto._id}`
        } else {
            return `/Empresarial/Producto?id=${Producto._id}`
        }
    }


    return (
        <div className="bg-white rounded-lg 
        w-[175px] h-[280px] sm:w-[250px] sm:h-[330px] md:w-[225px]  md:h-[330px] xl:w-[215px] xl:h-[300px] 2xl:w-[250px] 2xl:h-[330px]
        shadow-xl">

            <Link to={CheckType()} className="flex flex-col p-3 cursor-pointer">
                {/* Imagen del Producto */}
                {isLoading ? <img className="max-sm:w-[151px] max-sm:h-[113px] sm:w-[171px] sm:h-[129px] md:w-[171px] md:h-[129px] xl:w-[172px] xl:h-[129px] 2xl:w-[200px] 2xl:h-[150px] mx-auto" src={loadigGif} alt="Loading" /> : 
                <img className="max-sm:w-[151px] max-sm:h-[113px] sm:w-[171px] sm:h-[129px] md:w-[171px] md:h-[129px] xl:w-[172px] xl:h-[129px] 2xl:w-[200px] 2xl:h-[150px] mx-auto bg-gray-600 rounded-lg" src={prod.Foto != undefined || prod.Foto != null ? prod.Foto : ""} alt="Producto.png" />}

                {/* Nombre del producto */}
                <div className=" w-[100%] h-[75px] sm:h-[55px] flex">
                    <h3 className="text-center m-auto text-violet-400 font-bold
                    text-[16px] sm:text-[19px] xl:text-[18px]">{Producto.Nombre}</h3>
                </div>
                {/* Precio del producto */}
                <div className="flex">
                    <div className="mx-auto">
                        {Producto.Tipo == "domicilio" ?
                            <span>Precio: ${Producto.Precio}</span>
                            :
                            <span>
                                Consultar
                            </span>}
                    </div>
                </div>
                {/* Boton Ver Detalle */}
                <div className="flex mt-3">
                    <button className="border-2 border-violet-400 hover:bg-violet-300 rounded-full p-1 mx-auto"><span className="text-violet-400">Ver Detalle</span></button>
                </div>

            </Link>

        </div>
    )
}
import React from "react";

export default function TierCard(number, test) {
    return (<div className="bg-gray-800 w-[90%] mx-auto">
        <h3 className=" text-white">{number.test}</h3>
        <div className=" bg-white flex flex-col my-2 h-[100px] rounded-xl">
            <span className="font-bold ">Descripcion:</span>
            <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
            <p>Agregar una descripcion simple del producto</p>
            <input type="text" name={`Descripcion_${number.test}`} placeholder="Agregar una descripcion" className="border-2 border-black" defaultValue={number.number.Descripcion}/>
        </div>

        <div className=" bg-white flex flex-col my-2 h-[100px] rounded-xl">
            <span className="font-bold ">Precio:</span>
            <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
            <p>Asigne un Precio al producto.</p>
            <input type="number" name={`Precio_${number.test}`} className="border-2 border-black " defaultValue={number.number.Precio}/>
        </div>

        <div className=" bg-white flex flex-col my-2 h-[100px] rounded-xl">
            <span className="font-bold ">Disponible:</span>
            <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
            <p>Defina si el producto esta actualmente disponible para la venta. Una tilde significa que si.</p>
            <input type="checkbox" name={`Disponible_${number.test}`} className="border-2 border-black " defaultChecked={number.number.Disponible}/>
        </div>

        <div className=" bg-white flex flex-col my-2 h-auto rounded-xl">
            <span className="font-bold ">Detalle:</span>
            <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
            <p>Enumere cuales son los contenidos del pedido.</p>
            <textarea name={`Detalle_${number.test}`} placeholder="Agregar Detalles" className=" rounded-xl" defaultValue={number.number.Detalle ? number.number.Detalle.join("\n") : ""}/>
        </div>
    </div>)
}
